import React, {useEffect, useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import Order from './panels/Order';

const App = () => {
    const [activePanel, setActivePanel] = useState('home');
    const [fetchedUser, setUser] = useState(null);
    const [popout, setPopout] = useState(<ScreenSpinner size='large'/>);

    const homeResponse = async () => await fetch("/app")
        .then(function (res) {
            return res.ok ? res : Promise.reject(res);
        })
        .then(function (res) {
            setPopout(null);
            return res;
        })
        .then(res => res.json());

    useEffect(() => {
        bridge.subscribe(({detail: {type, data}}) => {
            if (type === 'VKWebAppUpdateConfig') {
                const schemeAttribute = document.createAttribute('scheme');
                schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
                document.body.attributes.setNamedItem(schemeAttribute);
            }
        });

        /*
        async function fetchData() {
            const user = await bridge.send('VKWebAppGetUserInfo');
            setUser(user);
        }

        fetchData();*/
    }, []);

    const go = e => {
        setActivePanel(e.currentTarget.dataset.to);
    };

    var parse_str = function (str, array) {
        var glue1 = '=';
        var glue2 = '&';

        var array2 = str.split(glue2);
        var array3 = [];
        for (var x = 0; x < array2.length; x++) {
            var tmp = array2[x].split(glue1);
            array3[unescape(tmp[0])] = unescape(tmp[1]).replace(/[+]/g, ' ');
        }

        if (array) {
            array = array3;
        } else {
            return array3;
        }
    }

   var q = parse_str(window.location.search.substr(1));

    switch (q['page'] || null) {
        case "order":
            return (
                <>
                    <Order id='home' fetchedUser={fetchedUser} homeResponse={homeResponse} go={go}/>
                </>
            );

        default:
            return (
                <>
                    <Home id='home' fetchedUser={fetchedUser} homeResponse={homeResponse} go={go}/>
                </>
            );
    }
}

export default App;

