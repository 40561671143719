import React from 'react';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import './css/style.css';
import Async from 'react-async';
import {Epic, PanelHeader, PanelHeaderContent, Tabbar, TabbarItem} from '@vkontakte/vkui';
import PanelHeaderButton from "@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton";
import Icon28Diary from '@vkontakte/icons/dist/28/article_outline';
import Icon28Tests from '@vkontakte/icons/dist/28/grid_square_outline';
import View from "@vkontakte/vkui/dist/components/View/View";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import logo from '../img/logo-sm.svg';
import logoFlag from '../img/logo-flag.svg?2';

class HomeClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            homeResponse: props.homeResponse,
            statusLogin: null,
            statusPassword: null,
            activeStory: 'home'
        };

        this.onAuth = this.onAuth.bind(this);
        this.onStoryChange = this.onStoryChange.bind(this);
    }

    onLogout = function (e) {
        fetch("/app", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"logout": 1})
        })
            .then(function (res) {
                return res.ok ? res : Promise.reject(res);
            })
            .then(res => res.json()).then(function (data) {
            if (data['success']) {
                window.location.reload();
            }
        });
    }

    onAuth() {
        var login = document.getElementById('login').value;
        var password = document.getElementById('password').value;
        var that = this;

        fetch("/app", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"auth": 1, "login": login, "password": password})
        })
            .then(function (res) {
                return res.ok ? res : Promise.reject(res);
            })
            .then(res => res.json())
            .then(function (data) {
                if (data['success']) {
                    window.location.reload();
                } else {
                    that.setState({statusLogin: data['statusLogin'] || null});
                    that.setState({statusPassword: data['statusPassword'] || null});
                }
            });
    };

    onStoryChange(e) {
        this.setState({'activeStory': e.currentTarget.dataset.story});
    };

    render() {
        const {homeResponse, statusLogin, statusPassword, activeStory} = this.state;
        return (


            <Async promiseFn={homeResponse}>
                <Async.Loading>

                </Async.Loading>
                <Async.Fulfilled>
                    {data => {
                        return (
                            <>
                                {data['need_auth'] ? (
                                    <>
                                        <PanelHeader>Авторизация</PanelHeader>
                                        <Div>
                                            <img className="logo-flag" src={logoFlag} alt="Expert English"/>
                                        </Div>
                                        <FormLayout>
                                            <Input
                                                type="text"
                                                top="Логин"
                                                id="login"
                                                name="login"
                                                placeholder="Введите логин"
                                                status={statusLogin}
                                                bottom={statusLogin === 'error' ? 'Пожалуйста, введите правильный логин' : ''}
                                                onChange={this.onChange}
                                            />
                                            <Input
                                                type="password"
                                                id="password"
                                                top="Пароль"
                                                placeholder="Введите пароль"
                                                status={statusPassword}
                                                bottom={statusPassword === 'error' ? 'Пожалуйста, введите правильный пароль' : ''}
                                            />
                                            <Button size="xl" onClick={this.onAuth}>Авторизоваться</Button>
                                        </FormLayout>
                                    </>
                                ) : (
                                    <>
                                        <Epic activeStory={this.state.activeStory} tabbar={
                                            <Tabbar>
                                                <TabbarItem
                                                    onClick={this.onStoryChange}
                                                    selected={activeStory === 'home'}
                                                    data-story="home"
                                                    text="Дневник"
                                                >
                                                    <Icon28Diary/>
                                                </TabbarItem>
                                                <TabbarItem
                                                    onClick={this.onStoryChange}
                                                    selected={activeStory === 'tests'}
                                                    data-story="tests"
                                                    text="Тесты"
                                                >
                                                    <Icon28Tests/>
                                                </TabbarItem>
                                                <TabbarItem
                                                    onClick={this.onStoryChange}
                                                    selected={activeStory === 'results'}
                                                    data-story="results"
                                                    text="Итоги"
                                                >
                                                    <Icon28Tests/>
                                                </TabbarItem>
                                            </Tabbar>
                                        }>
                                            <View id="home" activePanel="home">
                                                <Panel id="home">
                                                    <PanelHeader
                                                        left={data['can_logout'] ?
                                                            <img className="header-logo" src={logo} width="36" alt="Expert English"/>
                                                            : (<></>)}
                                                        right={data['can_logout'] ?
                                                            <PanelHeaderButton onClick={this.onLogout}>
                                                                Выйти
                                                            </PanelHeaderButton> : (<></>)}
                                                    >
                                                        <PanelHeaderContent
                                                            status={data['avg_rank']}
                                                        >
                                                            {data['user_name']}
                                                        </PanelHeaderContent>
                                                    </PanelHeader>
                                                    <Div>
                                                        <table className="schedule">
                                                            <tbody>
                                                            {data['schedules'].map((schedule, num) => (
                                                                <React.Fragment key={'fr-' + num}>
                                                                    {schedule['lessons'].map((lesson, idx) => (
                                                                        <tr key={num + '-' + idx}>
                                                                            {idx === 0 ? (
                                                                                <td className="day" rowSpan={schedule['lessons'].length}>
                                                                                    <span>{schedule.day}</span>
                                                                                </td>
                                                                            ) : (
                                                                                <>
                                                                                </>
                                                                            )}
                                                                            <td className="lesson">
                                                                                <div className="InfoRow__header">{lesson['name']}</div>
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: lesson['task']
                                                                                    }}
                                                                                ></div>
                                                                            </td>
                                                                            <td className="rate">
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: lesson['marks'].join('<br>')
                                                                                    }}
                                                                                ></div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    {schedule['note'] ? (
                                                                        <>
                                                                            <tr>
                                                                                <td className="note1"></td>
                                                                                <td className="lesson" colSpan={2}>
                                                                                    <div className="InfoRow__header">Комментарий преподавателя</div>
                                                                                    {schedule['note']}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="separator">
                                                                                <td colSpan="3">&nbsp;</td>
                                                                            </tr>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                        </>
                                                                    )}
                                                                    <tr className="separator">
                                                                        <td colSpan="3">&nbsp;</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </Div>
                                                </Panel>
                                            </View>
                                            <View id="tests" activePanel="tests">
                                                <Panel id="tests">
                                                    <PanelHeader
                                                        left={data['can_logout'] ?
                                                            <img className="header-logo" src={logo} width="36" alt="Expert English"/>
                                                            : (<></>)}
                                                        right={data['can_logout'] ?
                                                            <PanelHeaderButton onClick={this.onLogout}>
                                                                Выйти
                                                            </PanelHeaderButton> : (<></>)}
                                                    >
                                                        <PanelHeaderContent
                                                            status={data['avg_rank']}
                                                        >
                                                            {data['user_name']}
                                                        </PanelHeaderContent>
                                                    </PanelHeader>

                                                    <Div>
                                                        {data['tests'].length === 0 ? (
                                                            <div>Тесты не найдены</div>
                                                        ) : (
                                                            <table className="schedule">
                                                                <tbody>
                                                                {data['tests'].map((test, num) => (
                                                                    <React.Fragment key={'ts-' + num}>
                                                                        <tr>
                                                                            <td className="day" rowSpan="1">
                                                                                <span>{test.day}</span>
                                                                            </td>
                                                                            <td className="lesson">
                                                                                <div>{test['name']}</div>
                                                                            </td>
                                                                            <td className="rate">
                                                                                {test['value']}/{test['of']}({test['char']})
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                    </Div>
                                                </Panel>
                                            </View>
                                            <View id="results" activePanel="results">
                                                <Panel id="results">
                                                    <PanelHeader
                                                        left={data['can_logout'] ?
                                                            <img className="header-logo" src={logo} width="36" alt="Expert English"/>
                                                            : (<></>)}
                                                        right={data['can_logout'] ?
                                                            <PanelHeaderButton onClick={this.onLogout}>
                                                                Выйти
                                                            </PanelHeaderButton> : (<></>)}
                                                    >
                                                        <PanelHeaderContent
                                                            status={data['avg_rank']}
                                                        >
                                                            {data['user_name']}
                                                        </PanelHeaderContent>
                                                    </PanelHeader>
                                                    <Div>
                                                        <table className="schedule">
                                                            <tbody>
                                                            <tr>
                                                                <td className="lesson with-left-border">Итоговая оценка (1-е полугодие)</td>
                                                                <td className="rate">{data['result_marks'].mark_1 ? data['result_marks'].mark_1 : "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="lesson with-left-border">Итоговая оценка (2-е полугодие)</td>
                                                                <td className="rate">{data['result_marks'].mark_2 ? data['result_marks'].mark_2 : "-"}</td>
                                                            </tr>

                                                            {data['result_marks'].final_exam ? (
                                                                <>
                                                                    <tr>
                                                                        <td className="lesson with-left-border">Итоговый экзамен</td>
                                                                        <td className="rate">{data['result_marks'].final_exam ? data['result_marks'].final_exam : "-"}</td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <>
                                                                </>
                                                            )}


                                                            <tr>
                                                                <td className="lesson with-left-border">Годовая итоговая оценка</td>
                                                                <td className={data['result_marks'].mark_total_comment ? 'rate rate-comment' : 'rate'}>{data['result_marks'].mark_total ?
                                                                    data['result_marks'].mark_total :
                                                                    (data['result_marks'].mark_total_comment ? data['result_marks'].mark_total_comment : "-")
                                                                }</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </Div>
                                                </Panel>
                                            </View>
                                        </Epic>
                                    </>
                                )}
                            </>
                        )
                    }}
                </Async.Fulfilled>
                <Async.Rejected>
                    {error => `Something went wrong: ${error.message}`}
                </Async.Rejected>
            </Async>


        )
    }
}

export default HomeClass;