import React from 'react';
import './css/style.css';
import Async from 'react-async';
import {Alert, PanelHeader} from '@vkontakte/vkui';
import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import Radio from "@vkontakte/vkui/dist/components/Radio/Radio";

class OrderClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            homeResponse: props.homeResponse,
            errors: {},
            who: 'child',
            sent: false
        };

        this.onOrder = this.onOrder.bind(this);
        this.onWhoChange = this.onWhoChange.bind(this);
    }

    onOrder() {
        var that = this;
        fetch("/app", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "route": 'makeOrder',
                "name": document.getElementById('name').value,
                "age": document.getElementById('age') ? document.getElementById('age').value : null,
                "phone": document.getElementById('phone').value,
                "target": document.getElementById('target').value,
                "parent_fio": document.getElementById('parent_fio') ? document.getElementById('parent_fio').value : null,
                "who": this.state.who
            })
        })
            .then(function (res) {
                return res.ok ? res : Promise.reject(res);
            })
            .then(res => res.json())
            .then(function (data) {
                if (data['success']) {
                    that.setState({'sent': true});
                } else {
                    that.setState({errors: data['errors']});
                }
            });
    };

    onWhoChange(e) {
        this.setState({'who': e.currentTarget.value});
    };

    closePopout(e) {
        window.location.reload();
    }

    render() {
        const {homeResponse, errors} = this.state;
        return (
            <Async promiseFn={homeResponse}>
                <Async.Loading>

                </Async.Loading>
                <Async.Fulfilled>
                    {data => {
                        return (
                            <>
                                <PanelHeader>Запись на обучение</PanelHeader>
                                {this.state.sent ? (
                                    <Alert
                                        actionsLayout="vertical"
                                        actions={[{
                                            title: 'OK',
                                            autoclose: true,
                                        }]}
                                        onClose={this.closePopout}
                                    >
                                        <h2>Спасибо за вашу заявку!</h2>
                                        <p>Наш специалист обязательно свяжется с Вами по телефону в самое ближайшее время.</p>
                                    </Alert>
                                ) : (
                                    <FormLayout>
                                        <div>
                                            <Radio name="radio" value="child" defaultChecked onClick={this.onWhoChange}>Ребенок</Radio>
                                            <Radio name="radio" value="adult" onClick={this.onWhoChange}>Взрослый</Radio>
                                        </div>

                                        {this.state.who === 'child' && <Input
                                            type="text"
                                            top="ФИО родителя"
                                            id="parent_fio"
                                            name="parent_fio"
                                            placeholder="Введите ФИО родителя"
                                            status={errors['parent_fio'] ? 'error' : null}
                                            bottom={errors['parent_fio']}
                                            onChange={this.onChange}
                                        />}

                                        <Input
                                            type="text"
                                            top="ФИО ученика"
                                            id="name"
                                            name="name"
                                            placeholder="Введите ФИО ученика"
                                            status={errors['name'] ? 'error' : null}
                                            bottom={errors['name']}
                                            onChange={this.onChange}
                                        />

                                        {this.state.who === 'child' && <Input
                                            type="text"
                                            top="Возраст ребенка"
                                            id="age"
                                            name="age"
                                            placeholder="Введите возраст"
                                            status={errors['age'] ? 'error' : null}
                                            bottom={errors['age']}
                                            onChange={this.onChange}
                                        />}

                                        <Input
                                            type="text"
                                            top="Цель занятий"
                                            id="target"
                                            name="target"
                                            placeholder="Какие языковые навыки хотели бы улучшить"
                                            status={errors['target'] ? 'error' : null}
                                            bottom={errors['target']}
                                            onChange={this.onChange}
                                        />

                                        <Input
                                            type="text"
                                            top="Номер телефона для связи"
                                            id="phone"
                                            name="phone"
                                            placeholder="Введите номер телефона"
                                            status={errors['phone'] ? 'error' : null}
                                            bottom={errors['phone']}
                                            onChange={this.onChange}
                                        />

                                        <Button size="xl" onClick={this.onOrder}>Отправить</Button>
                                    </FormLayout>
                                )}
                            </>
                        )
                    }}
                </Async.Fulfilled>
                <Async.Rejected>
                    {error => `Something went wrong: ${error.message}`}
                </Async.Rejected>
            </Async>


        )
    }
}

export default OrderClass;